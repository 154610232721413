import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";

export default function SslPricing(propss) {
	const data = useStaticQuery(graphql`
		query validationLevelQuery {
			allProducts(filter: { productGroupID: { eq: 2 } }) {
				nodes {
					productID
					productGroupID
					annually
				}
			}
		}
	`);
	return (
		<div className="mx-3 text-center p-4 border-solid border-gray-200 border-2 rounded-lg my-16 hover:border-blue-600">
			<div>
				<img
					className="mx-auto w-1/5 my-4"
					src={propss.icon}
					alt="blue lock icon "
				></img>
			</div>
			<h4 className="text-black text-2xl mobile:text-2xl text-center font-extrabold my-6">
				{propss.header}
			</h4>

			<div className="w-full h-24 flex flex-wrap content-center justify-center text-center bg-gray-50">
				<div>
					<p className=" text-gray-400">Starting from</p>
					<sup className="text-black font-semibold text-2xl mobile:text-xl">
						$
					</sup>{" "}
					{data.allProducts.nodes.map(function (items, index) {
						return items.productID == propss.ID ? (
							<span
								key={index}
								className="m-auto text-blue-600 font-black text-5xl mobile:text-3xl"
							>
								{items.annually}
							</span>
						) : (
							""
						);
					})}
					<span className="text-gray-400 font-semibold text-xl mobile:text-lg">
						/yr
					</span>
				</div>
			</div>
			<div className="px-2 my-5">
				<p>{propss.sub_heading}</p>
			</div>
			<div className=" text-center my-3">
				<button className="bg-blue-600 text-xl   px-12 text-white py-3 m-auto rounded-xl  ">
					<Link to={propss.buttonLink}>Learn More</Link>
				</button>
			</div>
		</div>
	);
}
SslPricing.PropTypes = {
	ID: PropTypes.string.isRequired, // must be a number and defined
	sub_heading: PropTypes.string.isRequired, // must be a number and defined
	header: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	buttonLink: PropTypes.string,
};
