import React from "react";
import Tabs from "react-responsive-tabs";
import Content from "./sslPricingContent";
// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
const tabs = [
  {
    title: "View All SSL Certificates",
    content: <Content type={"All"} products={[]} />,
  },
  {
    title: "Standard SSL (DV)",
    content: <Content type={"DV"} products={[4, 6]} />,
  },
  {
    title: "Organisation Validation (OV)",
    content: <Content type={"OV"} products={[8, 11, 12]} />,
  },
  {
    title: "Extended Validation (EV)",
    content: <Content type={"EV"} products={[9, 13, 14]} />,
  },
  {
    title: "Wildcard",
    content: <Content type={"Wildcard"} products={[5, 7, 10]} />,
  },
];

function getTabs() {
  return tabs.map((tab, index) => ({
    title: tab.title,
    getContent: () => tab.content,
    /* Optional parameters */
    key: index,
    tabClassName: "sslTab",
    panelClassName: "sslPanel",
  }));
}

function Tab() {
  return <Tabs items={getTabs()} transform={false} showMore={false} />;
}
export default Tab;
