import React from "react";
import PropTypes from "prop-types";

function WhatIsSsl(propss) {
	return (
		<div className="px-16 py-2 mobile:px-6 flex flex-row">
			<div className="mobile:text-center px-2 ">
				<svg
					className="inline"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM15.1013 8.85406L9.87293 14.0825C9.57797 14.3774 9.17781 14.5432 8.76062 14.5432C8.34344 14.5432 7.94332 14.3774 7.64832 14.0824L4.89879 11.3329C4.28449 10.7186 4.28449 9.72258 4.89883 9.10824C5.51316 8.49398 6.50914 8.49395 7.12344 9.10828L8.76066 10.7456L12.8767 6.62953C13.4911 6.01523 14.487 6.01523 15.1014 6.62957C15.7156 7.24375 15.7156 8.23977 15.1013 8.85406Z"
						fill={propss.tickC}
					/>
				</svg>
			</div>
			<span className="text-sm font-normal		 mobile:text-md mobile:text-center  pt-1  inline uppercase">
				{propss.header}
			</span>
		</div>
	);
}
export default WhatIsSsl;

WhatIsSsl.PropTypes = {
	header: PropTypes.string.isRequired, // must be a string and defined
	color: PropTypes.string, // must be a string and defined
};
