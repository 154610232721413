import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery, Link } from "gatsby";

export default function tabsContent(props) {
  const data = useStaticQuery(graphql`
    query SslQuery {
      allProducts(filter: { productGroupID: { eq: 2 } }) {
        nodes {
          productID
          productGroupID
          name
          description
          monthly
          quarterly
          semiannually
          annually
          biennially
          triennially
        }
      }
    }
  `);

  let type = props.type;
  let IDs = props.products;
  return (
    <section className="grid grid-cols-2 gap-2 mobile:grid-cols-1">
      {data.allProducts.nodes.map((item, index) => {
        return IDs.includes(item.productID) ||
          item.type === type ||
          type === "All" ? (
          <div key={index} className="border-gray-100 border-2  p-4 rounded">
            <div className="mobile:text-center">
              <h4 className="text-md text-gray-700">{item.name}</h4>
              <p className="text-sm text-gray-500 font-semibold ">
                {item.description.split("\n")[0]}
              </p>
            </div>
            <div className="flex justify-between items-end pt-10 mobile:block mobile:justify-items-center">
              <div className="text-md text-gray-500 text-left  mobile:text-center mobile:px-0">
                Starting from
                <div className="text-2xl  text-gray-900 font-semibold mobile:text-xl">
                  ${item.annually} USD<span className="font-normal">/yr</span>
                </div>
              </div>

              <div className="mobile:text-center">
                <Link to={`/SSL/${type == "All" ? "" : type}#pricing`}>
                  <button className=" outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white rounded-3xl px-8 border-2 m-2  uppercase laptop:mt-2">
                    Buy Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </section>
  );
}
tabsContent.propTypes = {
  type: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
};
