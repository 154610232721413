import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import validationLevel from "../DB/SSL-choose-validation";
import Landing from "../components/landingSection";
import icons from "../DB/icon_list";
import ImageLeft from "../components/imageLeft";
import ImageRight from "../components/imageRight";
import SslPricing from "../components/SSL_COMP/ssl_pricing";
import WhatIsSsl from "../components/SSL_COMP/WhatIsSsl";
import Image from "../components/imageProcessing";
import Icon_list from "../components/SSL_COMP/icon_list";
import Accordion from "../components/accordion";
import AccordionContent from "../DB/ssl-FAQ";
import Pricing from "../components/SSL_COMP/sslPricing";
import TrustedBrands from "../components/SSL_COMP/trustedbrands";
import NavBar from "../components/SSL_COMP/SSL-nav-bar";
/* eslint react/prop-types: 0 */

function ssl() {
	return (
		<Layout>
			<SEO
				keywords={[
					`best ssl`,
					`ssl comparison`,
					`ssl certificate`,
					`comparison`,
				]}
				title="SSL Certificates"
				lang="en"
				image={"/hostylus-card-preview.jpg"}
				description="Find the best SSL Certificate using our SSL Comparison charts and reviews. Learn about SSL, read reviews, and compare SSL certificates."
			/>

			<Landing
				image={
					<div className=" ">
						{" "}
						<Image
							image={"sslLanding.png"}
							alt={" blue lock with user data in the background"}
						/>{" "}
					</div>
				}
				header={"SSL Certificates"}
				sub_header={
					"Secure your site and add trust & confidence for your visitors. With a range of brands, we have the right certificate for all your site security needs "
				}
				btnText={"Switch to Us"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<NavBar />

			<section
				id="pricing"
				className="w-4/5 mx-auto my-16 mobile:w-full tablet:mx-auto mobile:pb-3 tablet:mx-5"
			>
				<h2 className="text-4xl text-black font-bold text-center">
					Choose your level of validation
				</h2>
				<div className="grid grid-cols-3 mobile:block tablet:block">
					{validationLevel.map(function (items, index) {
						return (
							<SslPricing
								key={index}
								ID={items.ID}
								sub_heading={items.sub_heading}
								header={items.header}
								icon={items.icon}
								buttonLink={items.buttonLink}
							/>
						);
					})}
				</div>
				<div className="mx-auto w-full text-center">
					<p className=" text-blue-700 text-lg">
						<a href="#helpToChoose">
							Not sure which to choose? Let us help you decide &#8595;
						</a>
					</p>
				</div>
			</section>

			<section className="flex flex-row  py-10 mobile:flex-col-reverse tablet:flex-col-reverse laptop:flex-col-reverse desktop:mx-20 wide-desktop:mx-20 ">
				<div className="px-10 laptop:pr-0 mobile:px-0.5 w-1/2 mobile:w-full tablet:w-full laptop:w-full">
					<h2 className="font-bold text-3xl px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						What is SSL?
					</h2>
					<p className="py-5 text-lg px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						SSL Certificates are fundamental to internet security. They are used
						to establish an encrypted connection and allow data to be
						transmitted securely between a browser or user&apos;s computer and a
						server or website.
					</p>
					<WhatIsSsl
						tickC="#00C891"
						header="Establishes a secure connection between a browser and a server"
					/>
					<WhatIsSsl
						tickC="#00C891"
						header="Encrypts communication to protect sensitive information your customers provide to you"
					/>
					<WhatIsSsl
						tickC="#00C891"
						header="Places a padlock next to your web address in the browser"
					/>
					<WhatIsSsl
						tickC="#00C891"
						header="Authenticates an organization's identity"
					/>
					<p className="py-3 text-lg text-gray-600 px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						Standard SSL (Domain Validated) Certificates are the easiest and
						most common type of SSL certificate. OV and EV Certificates also
						authenticate the identity of the company or organization that holds
						the certificate providing more trust to end users.
					</p>
					<p className="py-3 text-lg text-gray-600 px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						With our SSL certificate services*, you won&apos;t need to take
						manual steps to configure and deploy new certificates every year. We
						generate a renewal order automatically when your certificate nears
						the end of its validity. After payment, we will automatically
						configure, validate, and provision your new certificate for you.
					</p>
					<p className="py-5 text-sm text-gray-600 px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						* Your domain must be hosted through the same hosting provider who
						sold the SSL certificate, and the server must use cPanel, Plesk, or
						DirectAdmin.
					</p>
				</div>
				<div className=" w-1/2 m-auto mobile:w-full tablet:w-full laptop:w-full ">
					<div className="shadow-xl w-max mx-auto mobile:w-4/5 tablet:w-4/5 laptop:w-10/12 laptop:mx-18 laptop:mb-16">
						<img
							src={"/ssl/secure.svg"}
							alt={"secured green bar lock"}
							className="mx-auto laptop:w-full tablet:w-full"
						/>
					</div>
				</div>
			</section>
			<section className="w-full py-10">
				<ImageLeft
					header={"Improve Your Search Engine Ranking"}
					smallHeader={
						"Improve Your Search Engine RankingEstablish trust and online security for your website visitors and business."
					}
					desc={
						"Establish trust and online security for your website visitors and business.Google wants to make the web safer and a big part of that involves making sure that the sites people access via Google are secure. That's why websites using SSL have been shown to benefit from higher ranking in search results."
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image
								image={"ssl/ranking.svg"}
								alt={
									"Number one highlighted in blue as top rank on search engines "
								}
								className="shadow-2xl"
							/>
						</div>
					}
				/>
			</section>
			<section className="mx-auto text-center py-10">
				<div>
					<p className="text-3xl font-bold w-full px-10 mobile:px-6 mobile:text-xl">
						There&apos;s also a lot more reasons why you should consider adding
						SSL to your website:
					</p>
				</div>
				<Icon_list icons={icons} columns={"3"} />
			</section>
			<section className="w-full p-20 mobile:px-6 bg-gradient-to-l from-blue-600 to-blue-900   my-20">
				<h2 className=" font-extrabold text-4xl text-center text-white">
					Browsers have changed, don&apos;t get left behind
				</h2>
				<div className="w-3/5 text-center my-10 mx-auto mobile:w-full ">
					<Image
						image={"Screenshot_2.png"}
						alt={" a window with a red lock showing non https web pages"}
					/>
				</div>
				<h3 className=" px-10 mobile:px-6 font-semibold text-xl text-center  text-gray-100">
					Secure your site in minutes with our fully automated provisioning and
					deployment
				</h3>
			</section>

			<section id="helpToChoose">
				<div className="mx-auto my-10 text-center px-16 mobile:px-6">
					<h2 className="font-bold text-3xl px-16 mobile:px-6 mobile:text-center mobile:mt-6 tablet:mt-6">
						Help Me Choose The Right Type of Certificate
					</h2>
					<p className="py-5  w-9/12 mx-auto text-gray-500 text-lg mobile:w-full mobile:text-center mobile:mt-6 tablet:mt-6">
						SSL Certificates are fundamental to internet security. While the
						need for online security is universal, not all SSL Certificates are
						created equal. The guide below is designed to help you decide which
						type of certificate is right for you.
					</p>
				</div>
				<ImageRight
					header={"Domain Validation (DV)"}
					listItems={
						<>
							<li>Verifies ownership and control of the domain name only</li>
							<li>Issued in minutes</li>
							<li>Maintains browser compliance</li>
						</>
					}
					smallHeader={"Ideal for non-critical web pages"}
					desc={
						"Think of DV like getting a library card—no confirmation of who you really are, very minimal requirements to obtain and issued very quickly."
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image
								image={"ssl/DV.svg"}
								alt={" library card for domain validation"}
								className="shadow-2xl"
							/>
						</div>
					}
					btnText={"Browse DV Certificates"}
					btnLink={"/SSL/DV"}
				/>
				<div className="my-20">
					<ImageLeft
						header={"Organization Validation (OV)"}
						listItems={
							<>
								<li>
									Enhanced validation including authenticating the identity of
									the applicant
								</li>
								<li>Issued within one day</li>
								<li>Maintains browser compliance</li>
							</>
						}
						smallHeader={
							"Ideal for more sensitive webpages such as login pages"
						}
						desc={
							"Think of OV like getting a driver’s license—more hoops to jump through than a library card but more trusted as a form of identification."
						}
						hideShopNow={"hidden"}
						hideArrow={"hidden"}
						image={
							<div className="shadow-xl w-max mx-auto mobile:w-4/5">
								<Image
									image={"ssl/OV.svg"}
									alt={" organization validation id"}
									className="shadow-2xl"
								/>
							</div>
						}
						btnText={"Browse OV Certificates"}
						btnLink={"/SSL/OV"}
					/>
				</div>
				<div className="my-20">
					<ImageRight
						header={"Extended Validation (EV)"}
						listItems={
							<>
								<li>
									Standards-based approach to authentication, representing the
									highest level of authentication for SSL Certificates
								</li>
								<li>Typically issued within 1-3 days</li>
								<li>Maintains browser and other industry compliance</li>
							</>
						}
						smallHeader={
							"Ideal for sensitive webpages including ecommerce, online banking, account signups"
						}
						desc={
							"Think of EV like getting a passport—much more stringent processes, longer lead times and more verification of who you are than with a library card or driver’s license. Recognized internationally as the most trusted way to verify your identity."
						}
						hideShopNow={"hidden"}
						hideArrow={"hidden"}
						image={
							<div className="shadow-xl w-max mx-auto mobile:w-4/5">
								<Image
									image={"ssl/EV.svg"}
									alt={" extended validation id"}
									className="shadow-2xl"
								/>
							</div>
						}
						btnText={"Browse EV Certificates"}
						btnLink={"/SSL/EV"}
					/>
				</div>
			</section>
			<section id="pricing">
				<div className="sslPricing max-w-5xl mx-auto  py-16 mobile:px-3 mobile:py-8 tablet:px-6 mobile:py-10">
					<h2 className="text-3xl text-white py-8 text-center">
						SSL Certificates
					</h2>
					<Pricing />
				</div>
			</section>

			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl mobile:px-2">
					<h2 className="text-center text-black mb-16 text-4xl font-bold uppercase">
						Frequently Asked Questions
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
			<TrustedBrands />
		</Layout>
	);
}

export default ssl;
