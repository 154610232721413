export default [
  {
    image: "/ssl/1.svg",
    description: "Encrypt sensitive data",
  },
  {
    image: "/ssl/2.svg",
    description: "Protect user privacy",
  },

  {
    image: "/ssl/3.svg",
    description: "Secure online transactions",
  },
  {
    image: "/ssl/4.svg",
    description: "Prove legitimacy",
  },
  {
    image: "/ssl/5.svg",
    description: "Activate HTTPS and the lock icon",
  },
  {
    image: "/ssl/6.svg",
    description: "Increase SEO rank ",
  },
];
