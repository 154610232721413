export default [
  {
    ID: "4",
    // price:"17.95",
    sub_heading:
      "Domain validation only. Issued in minutes. Ideal for blogs, social media & personal websites.",
    header: "Domain Validation (DV)",
    icon: "/ssl/Domain Validation.svg",
    buttonLink: "/SSL/DV",
  },
  {
    ID: "8",
    // price:"99.00",
    sub_heading:
      "Basic identity verification. Ideal for small business websites and pages accepting sensitive information.",
    header: "Organization Validation (OV)",
    icon: "/ssl/Organization Validation (OV).svg",
    buttonLink: "/SSL/OV",
  },
  {
    ID: "9",
    // price:"1,499.00",
    sub_heading:
      "Full identity verification. Ideal for Business & Ecommerce sites looking to provide maximum visitor confidence.",
    header: "Extended Validation (EV)",
    icon: "/ssl/Extended Validation (EV).svg",
    buttonLink: "/SSL/EV",
  },
];
